import React from 'react'
import { useTranslation } from 'react-i18next'
import UserMenu from '../../../components/UserMenu'

// Local components
import SidebarLogo from './SidebarLogo'
import SidebarLink from './SidebarLink'

// Utils
import ROUTES from '../../../config/routes'
import useAuth from '../../../state/auth/useAuth'

const Sidebar = ({ items }) => {
  const { t } = useTranslation('common')

  const { isGuest } = useAuth()

  return (
    <div className="h-screen fixed w-28 border-r border-gray-200">
      <div className="h-full flex flex-col items-center">
        <div className="my-8">
          <SidebarLogo />
        </div>

        <nav className="grow flex flex-col items-center justify-center">
          {items.map((item, idx) => {
            if (typeof item.href === 'undefined') {
              return item
            }

            return (
              <SidebarLink
                activeIcon={item.activeIcon}
                icon={item.icon}
                key={idx}
                title={item.label}
                to={item.href}
              />
            )
          })}
        </nav>

        <div className="mb-8">
          {isGuest === false && (
            <UserMenu
              onlyAvatar
              direction="top"
              items={[
                {
                  type: 'logout',
                },
                {
                  type: 'divider',
                },
                {
                  href: ROUTES.settings,
                  label: t('navbar.userMenu.settings'),
                },
                {
                  type: 'profile',
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
