import Page from '../_components/Page'
import PageHeader from '../_components/PageHeader'
import PageDescription from '../_components/PageDescription'
import React from 'react'
import useAuth from '../../../state/auth/useAuth'

const Home = () => {
  const { user } = useAuth()

  return (
    <Page>
      <PageHeader>Welcome, {user.first_name}</PageHeader>
      <PageDescription>Coming soon...</PageDescription>
    </Page>
  )
}

export default Home
