import React from 'react'
import Dashboard from './_components/Dashboard'
import SidebarSeparator from './_components/SidebarSeparator'
import ROUTES from '../../config/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Router } from '@reach/router'
import { useTranslation } from 'react-i18next'

// Pages
import Home from './_pages/Home'
import Notifications from './_pages/Notifications'
import Lessons from './_pages/Lessons'
import Referrals from './_pages/Referrals'
import Tutors from './_pages/Tutors'

const DashboardPage = () => {
  const { t } = useTranslation('pages')

  const dashboardSidebarItems = [
    {
      href: ROUTES.dashboard,
      label: t('dashboard.sidebar.dashboard'),
      icon: <FontAwesomeIcon fixedWidth icon={['fal', 'home']} />,
      activeIcon: <FontAwesomeIcon fixedWidth icon={['fas', 'home']} />,
    },
    {
      href: ROUTES.dashboardNotifications,
      label: t('dashboard.sidebar.notifications'),
      icon: <FontAwesomeIcon fixedWidth icon={['fal', 'bell']} />,
      activeIcon: <FontAwesomeIcon fixedWidth icon={['fas', 'bell']} />,
    },
    {
      href: ROUTES.dashboardLessons,
      label: t('dashboard.sidebar.lessons'),
      icon: <FontAwesomeIcon fixedWidth icon={['fal', 'books']} />,
      activeIcon: <FontAwesomeIcon fixedWidth icon={['fas', 'books']} />,
    },
    {
      href: ROUTES.dashboardTutors,
      label: t('dashboard.sidebar.tutors'),
      icon: <FontAwesomeIcon fixedWidth icon={['fal', 'users-class']} />,
      activeIcon: <FontAwesomeIcon fixedWidth icon={['fas', 'users-class']} />,
    },
    <SidebarSeparator key={'sep1'} />,
    {
      href: ROUTES.dashboardReferrals,
      label: t('dashboard.sidebar.referrals'),
      icon: <FontAwesomeIcon fixedWidth icon={['fal', 'chart-network']} />,
      activeIcon: <FontAwesomeIcon fixedWidth icon={['fas', 'chart-network']} />,
    },
  ]

  return (
    <Dashboard sidebarItems={dashboardSidebarItems}>
      <Router basepath={'/dashboard/'}>
        <Home path={'/'} />
        <Notifications path={'/notifications/'} />
        <Lessons path={'/lessons/'} />
        <Referrals path={'/referrals/'} />
        <Tutors path={'/tutors/'} />
      </Router>
    </Dashboard>
  )
}

export default DashboardPage
