import React from 'react'
import RightbarSeparator from './RightbarSeparator'
import Footer from '../../../components/Footer'

const Rightbar = () => {
  return (
    <div className="sticky top-0 min-h-screen w-full max-w-xs border-l border-gray-200">
      <div className="flex flex-col">
        <div className="mt-8 px-6">
          <button className={'btn btn-primary btn-rounded block mx-auto'}>
            {'Get unlimited access'}
          </button>

          <RightbarSeparator />

          <h3 className="text-md font-bold mb-6">Recommended Lessons</h3>

          <h3 className="text-md font-bold mb-6">New Lessons</h3>

          <h3 className="text-md font-bold mb-6">Top Tutors</h3>

          <Footer hideDateAndCompany isCentered={false} />
        </div>
      </div>
    </div>
  )
}

export default Rightbar
