import React from 'react'
import { navigate } from 'gatsby'
import { Code } from 'react-content-loader'
import { useTranslation } from 'react-i18next'

// Common Components
import UserCard from '../../../components/UserCard'

// Local Components
import Page from '../_components/Page'
import PageHeader from '../_components/PageHeader'
import PageDescription from '../_components/PageDescription'

// Utils
import useTutors from '../state/useTutors'
import ROUTES from '../../../config/routes'
import { SUBJECT_MATH } from '../../../utils/constants'

const Tutors = () => {
  const { t } = useTranslation('pages')
  const { isLoading, tutors } = useTutors()

  return (
    <Page>
      <PageHeader>{t('tutors.header')}</PageHeader>
      <PageDescription>{t('tutors.description')}</PageDescription>

      {isLoading && (
        <div className="w-full mx-auto mt-16">
          <Code className="mb-12" />
          <Code />
        </div>
      )}

      {tutors.length > 0 && (
        <div className="flex flex-wrap -mr-2 mt-6">
          {tutors.map((u, idx) => (
            <div key={`user-${idx}`} className={'w-1/2 pr-2 mb-8'}>
              <UserCard
                name={u.getFullName()}
                onClick={() => navigate(ROUTES.profile(u.username_slug))}
                image={u.getAvatar(400)}
                description={u.bio}
                subject={SUBJECT_MATH}
              />
            </div>
          ))}
        </div>
      )}
    </Page>
  )
}

export default Tutors
