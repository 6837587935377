import React from 'react'
import Sidebar from './Sidebar'
import Rightbar from './Rightbar'
import Header from './Header'
import useAuth from '../../../state/auth/useAuth'
import { Redirect, SplashPage, NakedLayout } from '../../../components/Layout'
import ROUTES from '../../../config/routes'

const Dashboard = ({ children, sidebarItems }) => {
  return (
    <NakedLayout isHeightFull>
      <main className="bg-white h-full overflow-auto relative">
        <div className={'max-w-7xl mx-auto'}>
          <div className="flex items-start justify-between">
            <Sidebar items={sidebarItems} />

            <div className="flex flex-col w-full ml-28">
              {false && <Header />}

              {children}
            </div>

            <Rightbar />
          </div>
        </div>
      </main>
    </NakedLayout>
  )
}

const DashboardProtected = (props) => {
  const { isLoaded, isGuest } = useAuth()

  if (isLoaded === false) {
    return <SplashPage />
  }

  if (isGuest) {
    return <Redirect to={ROUTES.home} />
  }

  return <Dashboard {...props} />
}

export default DashboardProtected
