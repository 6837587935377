import React from 'react'
import ROUTES from '../../../config/routes'
import Link from '../../../components/Link/GatsbyLink'
import lightSvg from '../../../static/logo/light.svg'

const SidebarLogo = () => {
  return (
    <Link to={ROUTES.home} className={'h-12 w-auto items-center justify-center'}>
      <img className="w-auto h-11 w-11" src={lightSvg} alt="Logo" />
    </Link>
  )
}

export default SidebarLogo
