import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import i18n from '../../config/i18n'

const t = i18n.getFixedT(null, 'common')

const SUBJECTS = {
  math: {
    icon: (
      <FontAwesomeIcon className={'h-6 w-6 text-white fill-current'} icon={['fas', 'function']} />
    ),
    label: t('subjects.math'),
  },
}

const UserCard = ({ name, image, description, onClick, subject }) => {
  const { icon, label } = SUBJECTS[subject]

  return (
    <div className="w-full max-w-sm bg-white rounded-lg overflow-hidden">
      {image ? (
        <img
          onClick={onClick}
          className="w-full h-56 object-cover object-center cursor-pointer"
          src={image}
          alt={`${name}`}
        />
      ) : (
        <div
          onClick={onClick}
          className="w-full h-56 flex items-center justify-center object-cover object-center cursor-pointer bg-gray-200"
        >
          <Avatar round fgColor={'white'} color={'black'} name={name} size="120" />
        </div>
      )}

      <div className="flex items-center px-6 py-3 bg-gray-900">
        {icon}

        <h1 className="mx-3 text-white font-semibold text-lg">{label}</h1>
      </div>

      <div className="py-4 px-1">
        <div
          onClick={onClick}
          className="text-2xl font-semibold font-bitter text-gray-800 cursor-pointer"
        >
          {name}
        </div>

        {description && <p className="py-2 text-sm text-gray-500">{description}</p>}
      </div>
    </div>
  )
}

UserCard.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  subject: PropTypes.oneOf(['math']).isRequired,
}

export default UserCard
