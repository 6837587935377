import Page from '../_components/Page'
import PageHeader from '../_components/PageHeader'
import PageDescription from '../_components/PageDescription'
import React from 'react'

const Notifications = () => {
  return (
    <Page>
      <PageHeader>Notifications</PageHeader>
      <PageDescription>You're all caught up.</PageDescription>
    </Page>
  )
}

export default Notifications
