import { useEffect, useCallback } from 'react'
import { useAtom } from 'jotai'

// Utils
import { tutorsData } from './atoms'
import api from '../../../apis'
import User, { UserData } from '../../../types/User'
import useAsync from '../../../hooks/useAsync'

const useTutors = () => {
  const [data, setData] = useAtom(tutorsData)
  const hasLoaded = data.tutors.length > 0

  const fetchTutors = useCallback(async () => api.user.getTutors(), [])
  const { execute, status, response, error } = useAsync(fetchTutors, false)

  /**
   * Fetch tutors if not in global state
   */
  useEffect(() => {
    if (hasLoaded === false) {
      execute()
    }
  }, [hasLoaded, execute])

  /**
   * Manage errors
   */
  useEffect(() => {
    if (error && status === 'error') {
      error.handleDefault()
    }
  }, [error, status])

  /**
   * Manage success - Save tutors to global state
   */
  useEffect(() => {
    if (response && status === 'success') {
      setData({
        isLoading: false,
        tutors: response.data.data.map((i: UserData) => new User(i)),
      })
    }
  }, [response, status, setData])

  return {
    ...data,
    reloadData: execute,
  }
}

export default useTutors
