import React, { useEffect, useState } from 'react'
import Page from '../_components/Page'
import PageHeader from '../_components/PageHeader'
import PageDescription from '../_components/PageDescription'
import api, { fetchAll } from '../../../apis'

const Lessons = () => {
  const [results, setResults] = useState(null)

  useEffect(() => {
    const fetchLessons = async () => {
      const data = await fetchAll(api.lesson.getLessons, {
        sort: '-created_at',
      })

      setResults(data)
    }

    fetchLessons()
  }, [])

  if (results === null) {
    return null
  }

  console.log(results)

  return (
    <Page>
      <PageHeader>Lessons</PageHeader>

      <PageDescription>Here's what's happening with your Academator account today.</PageDescription>

      <div className={'flex flex-wrap'}>
        {results.data.map((l) => {
          return (
            <div key={l.id} className={'shadow-variation6 bg-white rounded-lg p-12 w-1/3 mb-6'}>
              <h1>{l.name}</h1>
            </div>
          )
        })}
      </div>
    </Page>
  )
}

export default Lessons
