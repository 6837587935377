import Page from '../_components/Page'
import PageHeader from '../_components/PageHeader'
import PageDescription from '../_components/PageDescription'
import React from 'react'

const Referrals = () => {
  return (
    <Page>
      <PageHeader>Referrals</PageHeader>
      <PageDescription>Coming soon...</PageDescription>
    </Page>
  )
}

export default Referrals
