import { atom } from 'jotai'

export const lessonsInitData = {
  isLoading: true,
  response: null,
}

export const tutorsInitData = {
  isLoading: true,
  tutors: [],
}

export const lessonsData = atom(lessonsInitData)

export const tutorsData = atom(tutorsInitData)
