import React from 'react'
import { Link, Match } from '@reach/router'
import classnames from 'classnames'

const SidebarLink = ({ to, title, icon, activeIcon }) => {
  return (
    <Match path={to}>
      {({ match }) => (
        <Link
          title={title}
          getProps={({ isCurrent }) => {
            return {
              className: classnames({
                'py-3 px-6 my-2 flex text-2xl items-center transition-colors duration-200 justify-center': true,
                'text-gray-800': isCurrent,
                'text-gray-400': isCurrent === false,
              }),
            }
          }}
          to={to}
        >
          {match ? activeIcon : icon}
        </Link>
      )}
    </Match>
  )
}

export default SidebarLink
